import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import {Card} from '@mui/material';
import Layout from '../layouts';

const styles = ({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
    },
    maincard: {
        flex: '1 1 auto',
        padding: 20,
        maxWidth: 600,
        marginBottom: 20
    },
    sidebar: {
        flex: '1 1 auto',
        maxWidth: 360,
        width: '100%',
        verticalAlign: 'top'
    },
    sidecard: {
        maxWidth: 360,
        padding: 20,
        verticalAlign: 'top',
        marginBottom: 20
    },
    img: {
        maxWidth: 320,
        width: '100%',
        height:'100%',
        display:'block',
        transition: '0.3s'
    }
});

const BookPage = (props) => (
    <Layout pageName="Book">
    <div style={styles.main}>
        <Card style={styles.maincard}>
            <p>Helene's book, <em>Hamster on the Wheel; Cultivating Work-Life Balance</em>, was published in 2010.</p>

            <p>Helene is passionate about this topic because she has seen for herself how having some level of work-life
                balance enhances all areas of her life including work effectiveness and performance.</p>

            <p>In our fast paced technological culture, it is important more than ever to connect to our core values
                and positive beliefs.</p>

            <p>If this description resonates with you, please&nbsp;
                <a target="_blank" rel="noreferrer" href="http://www.amazon.com/Hamster-Wheel-Cultivating-Life-Balance/dp/061535016X">
                    click here to purchase the book</a>.</p>
            <br/>

            <div>
                <blockquote style={{color:'#080',maxWidth:'400px',margin:'0 auto',textAlign:'center'}}>"Be the change
                    that you want to see in the world"
                    <br/><br/><small><em>- Gandhi</em></small></blockquote>
            </div>

        </Card>
        <div style={styles.sidebar}>
            <Card style={styles.sidecard}>
                <div className="sideimg">
                    <GatsbyImage alt="Hamster on the wheel book cover" id="bookimg" style={styles.img} image={props.data.file.childImageSharp.gatsbyImageData} />
                </div>
            </Card>
        </div>
        </div>
        </Layout>
);

export const query = graphql`{
    file(name: {eq: "book"}) {
        childImageSharp {
            gatsbyImageData
        }
    }
}`;

export default BookPage;
